import React, { Component } from 'react';
import axios from "axios";

import { IoMdDownload } from 'react-icons/io';
import { IoIosSettings } from 'react-icons/io';
import { IoMdLogOut } from "react-icons/io";

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            users: [],
            originalLicensesValue: null,
            saveInProgress: false, // Flag to prevent duplicate saves
        };

        this.handleAppLicensesChange  = this.handleAppLicensesChange.bind(this)

    }

    componentDidMount() {
        document.getElementById("app-window").focus();
        this.getUsers();
    }

    handleAppLicensesChange(e, key) {
        const itemValue = e.target.value;

        // Create a copy of the current state
        this.setState((prevState) => {
            const updatedUsers = [...prevState.users];

            // Modify the specific user license value in the copy
            updatedUsers[key] = {
                ...updatedUsers[key],
                licenses: itemValue,
            };

            // Return the new state object
            return { users: updatedUsers };
        });
    }

    handleAppLicensesFocus = (e, key) => {
        // Store the original value before editing
        this.setState({ originalLicensesValue: this.state.users[key].licenses });
        //console.log(this.state.originalLicensesValue);
    }

    handleAppLicensesSave = (e, key) => {

        if (this.state.saveInProgress) return;

        // Update state and then remove focus
        this.setState({ saveInProgress: true }, () => {
            // Remove focus from the input after state has been updated
            e.target.blur();
        });

        const itemName              = e.target.name;
        const companyAppLicenses    = e.target.value;

        const isConfirmed = window.confirm('Are you sure you want to update the number of licenses to ' + companyAppLicenses);

        if (isConfirmed) {

            const splitName             = itemName.split('_');
            const companyId             = splitName[1];

            var data                    = {};

            data.eventId                = this.props.eventId;
            data.companyId              = companyId;
            data.companyAppLicenses     = companyAppLicenses;
            data.token                  = this.props.token;

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=updateCompanyLicenses',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                if(responseObject.data.valid_token === true) {

                    this.setState({ message: responseObject.data.status.message});

                    this.props.connectionError(false);

                } else {

                    console.log("fail");

                }

                // After saving, reset the flag
                this.setState({ saveInProgress: false });

            }).catch((error) => {

                this.setState({ message: error});
                this.props.connectionError(true);
                console.log(error)

                // After saving, reset the flag
                this.setState({ saveInProgress: false });

            });

        } else {
            // Action was canceled

            this.setState((prevState) => {
                // Create a copy of the current users array from the state
                const updatedUsers = [...prevState.users];

                // Update the specific user's licenses value to the original value
                updatedUsers[key] = {
                    ...updatedUsers[key],
                    licenses: prevState.originalLicensesValue,
                };

                // Return the updated state
                return { users: updatedUsers };
            });

            this.setState({ message: 'Update action canceled'});

        }



    }

    getUsers() {

        var data            = {};

        data.event_id       = this.props.eventId;
        data.token          = this.props.token;

        this.setState({message: 'Receiving Leads'});

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: this.props.domain + '/core/connect.php?action=getUsers',
            data: JSON.stringify(data)

        }).then((responseObject) => {


            if(responseObject.data.valid_token === true) {

                //console.log(responseObject.data);

                this.props.connectionError(false);

                this.setState({ message: '' });
                this.setState({ users: responseObject.data.users });

            } else {

                var prop        = {};
                prop.appMessage = responseObject.data.result;
                this.props.changeScreen('setup', prop);

            }

        }).catch((error) => {

            console.log(error);
            this.props.connectionError(true);

        });

    }

    export() {

        window.open(this.props.domain + "/export-users/?eventId=" + this.props.eventId + "&companyId=" + this.props.companyId + "&key=" + this.props.exportKey, "_blank");
    }

    logUserOut(key, index, email, companyId) {
        //console.log(key);
        //console.log(index);
        const isConfirmed = window.confirm('Are you sure you want logout the user: ' + email);

        if (isConfirmed) {

            var data                    = {};

            data.eventId                = this.props.eventId;
            data.email                  = email;
            data.companyId              = companyId;
            data.token                  = this.props.token;

            //console.log(data);

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: this.props.domain + '/core/connect.php?action=logoutUser',
                data: JSON.stringify(data)

            }).then((responseObject) => {

                if(responseObject.data.valid_token === true) {

                    this.setState({ message: responseObject.data.status.message});
                    this.props.connectionError(false);

                    const usersCopy = [...this.state.users];

                    // Step 2: Copy the rows array of the specific user
                    const userRowsCopy = [...usersCopy[key].rows];

                    // Step 3: Remove the specific row
                    userRowsCopy.splice(index, 1); // Remove the 4th element (index 3)

                    // Step 4: Update the copied user's rows
                    usersCopy[key].rows = userRowsCopy;

                    // Step 5: Set the new state
                    this.setState({
                        users: usersCopy,
                    });

                } else {

                    console.log("fail");
                    this.props.connectionError(true);

                }

            }).catch((error) => {

                this.setState({ message: error});
                this.props.connectionError(true);
                console.log(error)

            });
        }

    }

    render() {

        const {companyName, initials, colors, logoTop, logoBottom } = this.props;

        const frontStyle    = {
            color: colors.color_1,
            textShadow:  '0px 4px 3px #00000030',
            whiteSpace: "pre-wrap"
        }

        const userStyle    = {
            color: colors.color_1 + 'BB'
        }

        const btnStyle = {
            color: colors.color_1,
            backgroundColor: colors.color_2,
            cursor: 'pointer',
        }

        const companyNameStyle = {
            borderTop: '1px solid' + colors.color_1 + 'BB',

        }

        return (

            <div className="container-fluid d-flex align-items-center vh-100">
                <div className="row d-flex justify-content-center vh-100 vw-100">
                    <div className="content-wrapper page-home col-12 col col-sm-8 col-lg-6">

                        {logoTop !== '' &&
                        <div className="section-top-wrapper">
                            <img id="logo" src={logoTop} alt="Logo"/>
                        </div>
                        }

                        <div className="mt-3 mb-3" style={frontStyle}><strong>Users</strong></div>

                        <div className="content">

                            {this.state.message !== "" &&
                                <div id="message" className="mb-3 text-center" style={frontStyle} dangerouslySetInnerHTML={{__html: this.state.message}}></div>
                            }

                            <div className="form-group text-center mb-4">

                                <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.props.changeScreen('admin', '')}>
                                    Back <IoIosSettings/>
                                </button>

                                {Object.keys(this.state.users).length > 0 &&
                                    <button className="btn-medium m-1" type="button" style={btnStyle} onClick={() => this.export()}>
                                        Export <IoMdDownload/>
                                    </button>
                                }

                            </div>

                            <div>

                                {Object.keys(this.state.users).map(key => (
                                    <div key={key}>
                                        <div className="users-company-name" style={companyNameStyle}>{ this.state.users[key].company_name }<span className="user-licenses">{this.state.users[key].rows.length}&nbsp;/
                                            <input
                                                name        = {`company_${this.state.users[key].company_id}`}
                                                className   = "app-licenses"
                                                value       = {this.state.users[key].licenses}
                                                onChange    = {(e) => this.handleAppLicensesChange(e, key)}
                                                onBlur      = {(e) => this.handleAppLicensesSave(e, key)}
                                                onKeyDown   = {(e) => {
                                                    if (e.key === 'Enter') {
                                                        this.handleAppLicensesSave(e, key);
                                                    }
                                                }}
                                                onFocus     = {(e) => this.handleAppLicensesFocus(e, key)}/>
                                        </span></div>
                                        {this.state.users[key].rows.map((item, index) => (
                                            <div className="user-row"  key={index}><span className="user-updated">{ item.updated }</span><span className="logout" onClick={() => this.logUserOut(key, index, item.email, item.companyId)}><IoMdLogOut/></span><span className="user-email">{item.email}</span></div>
                                        ))}
                                    </div>
                                ))}



                            </div>

                       </div>

                       <div className="section-bottom-wrapper mt-3">
                           <div className="form-group text-center" style={userStyle}>

                               <div id="user">
                                   <div>SIGNED IN AS</div>
                                   <div>Company: {companyName}</div>

                                   {initials !== '' &&
                                   <div>Initials: {initials}</div>
                                   }
                               </div>

                           </div>

                           {logoBottom !== '' &&
                           <div className="section-bottom-wrapper mt-5">
                               <div className="text-center">
                                   <img id="logo-bottom" src={logoBottom} alt="Logo"/>
                               </div>
                           </div>
                           }
                       </div>

                   </div>
               </div>
           </div>

        );
    }
}

export default Users;